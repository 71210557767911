<template>
  <div>
    testHome
  </div>
</template>

<style scoped>
</style>

<script>
export default {
  name: 'Login',
  data(){
    return {}
  },
}
</script>
